import React from 'react'

import { Helmet } from 'react-helmet'

const DatenschutzPage = ({ data }) => {
    return (
        <main>
            <Helmet>
                <meta content='#DCB4C0' name='theme-color' />
                <meta content='Einfach unvergessliche Trauungen. Einzigartig, wie eure Geschichte.' name='description' />
                <meta content='index, follow' name='robots' />
                <meta content='Traurednerin, Freie, Trauung, Hochzeit, Rheintal, freie Trauung' name='keywords' />
                <meta content='https://www.livia-traut.ch/datenschutz' property='og:url' />
                <meta content='Livia traut.' property='og:site_name' />
                <meta content='Livia Sinz' name='author' />
                <link href={require('../images/favicon.ico').default} rel='shortcut icon' />
                <link href={require('../images/apple-touch-icon.png').default} rel='apple-touch-icon' sizes='180x180' />
                <link href={require('../images/favicon-32x32.png').default} rel='icon' sizes='32x32' type='image/png' />
                <link href={require('../images/favicon-16x16.png').default} rel='icon' sizes='16x16' type='image/png' />
                <link href={require('../images/safari-pinned-tab.svg').default} rel='mask-icon' color='#DCB4C0' />
                <meta content='#DCB4C0' name='msapplication-TileColor' />
                <meta content='#DCB4C0' name='theme-color' />
                <meta property='og:image' content={require('../images/social-image.png').default} />
                <title>Livia traut | Freie Traureden.</title>
            </Helmet>
            <div className={'max-w-2xl mx-auto p-5 sm:p-10'}>
                <h1 className={'text-2xl font-bold mb-5'}>Datenschutzerklärung</h1>

                <section id="einleitung">
                    <h2>Einleitung</h2>
                    <p>Willkommen auf der Webseite von Livia Traut. Der Schutz Ihrer persönlichen Daten ist uns sehr wichtig. In dieser Datenschutzerklärung möchten wir Sie darüber informieren, welche Daten wir sammeln und wie diese verwendet und gespeichert werden.</p>
                </section>

                <section id="verantwortliche-stelle">
                    <h2>Verantwortliche Stelle</h2>
                    <p>Verantwortlich für die Datenverarbeitung auf dieser Webseite ist:</p>
                    <p>Livia Traut<br />
                        Marktgasse 14<br />
                        9450 Altstätten SG<br />
                        mail@livia-traut.ch
                    </p>
                </section>

                <section id="art-der-gesammelten-daten">
                    <h2>Art der gesammelten Daten</h2>
                    <p>Beim Besuch unserer Website können verschiedene Arten von Daten gesammelt werden:</p>
                    <ul>
                        <li><strong>Persönliche Daten:</strong> Beispielsweise Name, E-Mail-Adresse und Telefonnummer, die Sie beim Ausfüllen von Kontaktformularen angeben.</li>
                        <li><strong>Nutzungsdaten:</strong> Diese werden automatisch durch die Nutzung der Website erfasst und können IP-Adressen oder Browser-Typ umfassen.</li>
                    </ul>
                </section>

                <section id="verwendung-von-cookies">
                    <h2>Verwendung von Cookies</h2>
                    <p>Diese Webseite verwendet Cookies. Cookies sind kleine Textdateien, die auf Ihrem Gerät gespeichert werden, um bestimmte Informationen während Ihrer Navigation zu speichern und abzurufen. Wir verwenden sowohl eigene als auch Cookies von Dritten:</p>
                    <ul>
                        <li><strong>Google Ads:</strong> Zur Verbesserung der Werbung</li>
                        <li><strong>Google Analytics:</strong> Zur Sammlung von Website-Statistiken</li>
                        <li><strong>Matomo Tracking:</strong> Zur Optimierung der Benutzererfahrung</li>
                    </ul>
                    <p>Sie können Ihre Cookie-Einstellungen in Ihrem Browser jederzeit ändern.</p>
                </section>

                <section id="zweck-der-datenverarbeitung">
                    <h2>Zweck der Datenverarbeitung</h2>
                    <p>Die gesammelten Daten werden für verschiedene Zwecke verwendet:</p>
                    <ul>
                        <li>Um unseren Service bereitzustellen und aufrechtzuerhalten</li>
                        <li>Um den Kundenservice zu verbessern</li>
                        <li>Um die Benutzererfahrung zu analysieren und zu verbessern</li>
                    </ul>
                </section>

                <section id="rechtsgrundlage">
                    <h2>Rechtsgrundlage</h2>
                    <p>Die Verarbeitung Ihrer Daten erfolgt auf einer der folgenden Rechtsgrundlagen:</p>
                    <ul>
                        <li>Ihre Einwilligung</li>
                        <li>Vertragliche Notwendigkeiten</li>
                        <li>Gesetzliche Verpflichtungen</li>
                    </ul>
                </section>

                <section id="datensicherheit">
                    <h2>Datensicherheit</h2>
                    <p>Wir treffen alle notwendigen technischen und organisatorischen Maßnahmen, um die Sicherheit Ihrer Daten zu gewährleisten.</p>
                </section>

                <section id="ihre-rechte">
                    <h2>Ihre Rechte</h2>
                    <p>Nach der DSGVO haben Sie das Recht, Auskunft, Berichtigung, Löschung oder Einschränkung Ihrer gespeicherten Daten zu verlangen. Weiterhin steht Ihnen ein Widerspruchsrecht gegen die Verarbeitung sowie das Recht auf Datenübertragbarkeit zu.</p>
                </section>

                <section id="aenderungen-dieser-datenschutzerklaerung">
                    <h2>Änderungen dieser Datenschutzerklärung</h2>
                    <p>Wir behalten uns vor, diese Datenschutzerklärung zu aktualisieren, um sie an rechtliche Anforderungen anzupassen. Bitte überprüfen Sie diese Seite regelmäßig.</p>
                </section>

                <section id="kontakt">
                    <h2>Kontakt</h2>
                    <p>Für Fragen zur Datenschutzerklärung wenden Sie sich bitte an:<br />marc.fehr@gmail.com</p>
                </section>
            </div>
        </main>
    )
}

export default DatenschutzPage